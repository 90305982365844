/* ----------------- home Styles ---------------------- */
.adviser-home-main-div{
    background-color: #f2f3f4 !important;
    width: 100% !important;
    padding: 68px 20px 20px 20px !important;
    margin-top: 0 !important;
    height: 100vh !important;
}
.adviser-home-main-div h2{
    margin: 0 !important;
    color: rgb(0, 110, 182) !important;
    font-weight: 300 !important;
    font-size: 1.5em !important;
}

.meetings-list{
    min-height: 116px !important;
    background-color: white !important;
    margin: 0 !important;
    padding: 30px 40px !important;
    border-radius: 5px !important;
    display: flex !important;
    flex-direction: column !important;
    border: 1px solid rgb(217, 217, 217) !important;
}
.meetings-list .ant-alert span{
    color: white !important;
}
.meetings-list .ant-alert span[class='ant-alert-message']{
    font-weight: 500 !important;
}
.meetings-list .ant-alert.ant-alert-info > i{
    color: white !important;
    font-size: 16px !important;
    top: 20px !important;
}
.meetings-list .ant-alert.ant-alert-info:hover > i{
    color: white !important;
    font-size: 16px !important;
    top: 20px !important;
}
/* ---------------------------------------------------- */

/* ---------------- Meeting card styles --------------- */

.meeting-card {
    color: rgb(100, 100, 100);
    border-top: 1px solid rgba(0, 0, 0, 0.09);
    background: #fff;
    min-height: 90px;
    border-radius: 5px;
    padding: 10px 10px 10px 20px;
    display: flex;
    position: relative;
    box-shadow: 0 3px 6px -1px rgba(0, 0, 0, 0.45);
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}
.meeting-card > .card-div-information{
    width: 100%;
}

.meeting-data{
    text-align: center;
    align-self: center;
    margin: 5px 0;
}
.meeting-data b{
    font-size: 12px;
    font-weight: 700;
}
.meeting-card .ant-collapse{
    margin-top: 30px !important;
}
.meeting-card .ant-collapse .ant-collapse-content{
    margin-top: 10px !important;
}
.meeting-card .ant-collapse .ant-collapse-content .ant-form > .ant-row .ant-row.ant-form-item{
    margin: 0 !important;
}
.meeting-card .ant-collapse .ant-collapse-content .ant-form > .ant-row:first-child, .meeting-card .ant-collapse .ant-collapse-content .ant-form > .ant-row:nth-child(2){
    margin-bottom: 20px !important;
}
.meeting-card .ant-collapse .ant-collapse-content .ant-form h3{
    margin: 0 !important;
    font-size: 16px !important;
    font-weight: 500;
    color: rgba(0,0,0,0.85);
}
.meeting-card .ant-collapse .ant-collapse-item .ant-collapse-extra button{
    height: 28px !important;
    top: -3px !important;
}
.meeting-card .ant-collapse .meeting-detail-info-divider{
    margin: 15px !important;
}
.meeting-card .ant-collapse .ant-collapse-item .meeting-actions-div{
    margin-bottom: 10px !important;
    text-align: right !important;
}
.meeting-card .collapse-extra-content-div button{
    margin: 0 3px !important;
}
.meeting-card .meeting-actions-div button{
    margin: 0 5px 5px 5px !important;
    width: 100px !important;
}
@media screen and (max-width: 575px) {
    .meeting-card .ant-collapse .ant-collapse-item .ant-collapse-extra .collapse-extra-content-div{
        display: none;
    }
}
@media screen and (min-width: 576px) {
    .meeting-card .ant-collapse .ant-collapse-item .meeting-actions-div{
        display: none;
    }
}
.meeting-card .meeting-detail-button-col{
    text-align: right !important;
}
@media screen and (max-width: 767px) {
    .meeting-card .meeting-detail-button-col .ant-form-item-label{
        display: none !important;
    }
}

.meeting-notification{
    color: #ffe800 !important;
    font-size: 20px !important;
}

.adviser-home-main-div .meeting-card .react-tel-input .flag-dropdown{
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

/* --------------- Whereby ------------------- */
.adviser-home-main-div .whereby-button{
    position: absolute !important;
    z-index: 21;
    background: rgba(0,0,0,0.56);
    border: 0;
    color: white;
    font-weight: 500;
}
.adviser-home-main-div .whereby-button-max{
    height: 40px;
    width: 120px;
    top: 8px;
    right: 50%;
    left: 50%;
    margin-left: -60px;
}
.adviser-home-main-div .whereby-button-min{
    width: 50px;
    height: 50px;
    font-size: 30px;
    right: 0;
    top: 60px;
}
.adviser-home-main-div .whereby-button:hover,
.adviser-home-main-div .whereby-button:focus,
.adviser-home-main-div .whereby-button:active{
    background: rgba(0,0,0,0.65);
    border: 0;
    color: white;
    font-weight: 500;
}
.adviser-home-main-div iframe.whereby-iframe{
    display: block !important;
    position: absolute !important;
    top: 0 !important;
    min-width: 100% !important;
    width: 100vw !important;
    height: 100vh;
    border: 0 !important;
    left: 0;
    background-color: #0066ac;
    z-index: 20;
}
.adviser-home-main-div iframe.whereby-iframe-min{
    display: none;
}
.adviser-home-main-div .copy-link-container {
    z-index: 100;
    position: fixed;
    bottom: 4px;
    right: 8px;
}
@media screen and (max-width: 527px) {
    .adviser-home-main-div .copy-link-container {
        bottom: 80px;
        right: 12px;
    }
}
.adviser-home-main-div .copy-link-container button {
    width: 48px;
    height: 48px;
    border-radius: 10px;
    background-color: rgba(0,0,0,0.56);
    display: flex;
    padding: 10px;
    border: 0;
    margin: auto;
}
.adviser-home-main-div .copy-link-container button:hover {
    background-color: rgba(0,0,0,0.76);
}
.adviser-home-main-div .copy-link-container button > i {
    font-size: 24px;
    color: white;
    margin: auto;
}
.adviser-home-main-div .copy-link-container div {
    color: white;
    text-align: center;
    font-size: 12px;
    line-height: 14px;
    user-select: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 48px;
    margin-top: 8px;
    font-weight: lighter;
}